import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosRequestHeaders,
} from 'axios';
import { getToken, removeToken, UNAUTH_ERROR_CODE } from '@helpers/loginHelper';
import { queryStringify, isUsingStagingEnv } from '@helpers/index';
import { showErrorMessage } from './show-notification';
import { STG_API_ENDPOINT, PRD_API_ENDPOINT } from 'src/constants/appConfigs';

export const excludeValidate = 'generate-token';

export interface IErrorData {
  target: string;
  reason: string;
  message: string;
}

const customInstance = axios.create({
  baseURL: isUsingStagingEnv() ? STG_API_ENDPOINT :  PRD_API_ENDPOINT,
  timeout: 10000,
});

export const getLoginPath = (): string => {
  const redirectTo = window.location.pathname;

  let queryParams = '';
  if (redirectTo !== '/login') {
    queryParams = queryStringify({
      redirectTo,
      errorType: UNAUTH_ERROR_CODE,
    });
  }

  return `/login?${queryParams}`;
};

export const requestInterceptor = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const url = config?.url ?? '';
  if (url.indexOf(excludeValidate) > -1) {
    return config;
  }

  const newConfig: InternalAxiosRequestConfig = {
    ...config,
    headers: { ...config.headers } as AxiosRequestHeaders,
  };

  const token = getToken();
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  return newConfig;
};

export const responseSuccessInterceptor: any = (res: AxiosResponse<any, any>) =>
  res;

export const responseErrorInterceptor: any = (
  error: AxiosError<IErrorData>,
) => {
  showErrorMessage({});

  return Promise.reject(error);
};

customInstance.interceptors.request.use(requestInterceptor);
customInstance.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export default customInstance;
