/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
  BRANCH_HOSTNAME,
  DEV_HOSTNAME,
  PRD_HOSTNAME,
  STG_HOSTNAME,
} from 'src/constants/appConfigs';

dayjs.extend(duration);

export const isNotRunningTest = (): boolean =>
  process.env.JEST_WORKER_ID === undefined;

export const isProdEnv = (): boolean =>
  window?.location?.hostname === PRD_HOSTNAME;

export const isStagingEnv = (): boolean =>
  window?.location?.hostname === STG_HOSTNAME;

export const isDevEnv = (): boolean =>
  window?.location?.hostname === DEV_HOSTNAME;

export const isBranchEnv = (): boolean =>
  window?.location?.hostname.endsWith(`-${BRANCH_HOSTNAME}`);

export const isUsingStagingEnv = (): boolean =>
  isStagingEnv() || isDevEnv() || isBranchEnv();

export const getEnv = (): string => {
  if (isProdEnv()) return 'prd';
  if (isStagingEnv()) return 'stg';
  if (isDevEnv()) return 'dev';
  if (isBranchEnv()) return 'stg';
  return '';
};
export const getApiEnv = (): string => (isProdEnv() ? 'prd' : 'stg');

export const getFullEnv = (env: string): string => {
  switch (env) {
    case 'stg':
      return 'staging';
    case 'prd':
      return 'production';
    case 'misc':
      return 'miscellaneous';
    default:
      return '';
  }
};

export const getStringBetween = (
  str: string,
  start: string,
  end: string,
): string => {
  const result = str.match(new RegExp(`(?<=${start})(.*)(?=${end})`));
  return result?.[1] || '';
};

export const formatLongText = (src: string, maxLength: number): string => {
  if (src.length > maxLength) {
    return `${src.substring(0, maxLength)}...`;
  }
  return src;
};

export const formatJobDuration = (
  milliseconds: number,
  customFormat?: string,
): string => {
  if (milliseconds < 0) {
    return '-';
  }
  if (milliseconds < 1000) {
    return `${milliseconds}ms`;
  }
  return dayjs.duration(milliseconds).format(customFormat || 'H[h] m[m] s[s]');
};

export const camelCase = (str: string): string =>
  str
    .replace(/[-_]([a-z])/g, (g) => g[1].toUpperCase())
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');

export const formatTimestamp = (
  date: string | number | dayjs.Dayjs | Date | null | undefined,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'number' && date < 0) {
    return '-';
  }
  return dayjs(date).format(format);
};

export const capitalizeFirstLetter = (s: string | undefined): string =>
  s ? s[0].toUpperCase() + s.slice(1) : '';

export const accFromEmail = (email: string): string =>
  email?.replace('@grabtaxi.com', '') || '';

export const urlify = (text = ''): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
};

export const queryStringify = (obj: any): string =>
  `?${new URLSearchParams(obj).toString()}`;
