/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from 'react';
import { message } from '@duxton-web/ui';
import { AxiosError } from 'axios';
import { IErrorData } from '.';
import { urlify } from '..';

interface ShowErrorMessageProps {
  error?: AxiosError<IErrorData> | any;
  customMessage?: ReactNode;
  duration?: number;
  key?: string;
}

export const showErrorMessage = ({
  error,
  customMessage,
  duration = 3,
  key = '',
}: ShowErrorMessageProps): void => {
  if (!document) return; // fix unit test, 'createElement' of null

  let content: ReactNode =
    customMessage || error?.message || 'Something went wrong. Please try again!';

  if (error?.response?.data) {
    const { message: msg, reason } = error.response?.data;

    content = (
      <>
        <b>
          [{error.response.status}] {reason}
        </b>
        : <span dangerouslySetInnerHTML={{ __html: urlify(msg) }} />
      </>
    );
  }

  message.error({
    content,
    key,
    duration,
  });
};
