export const PRD_HOSTNAME = 'mobility-bot.grab.com';
export const STG_HOSTNAME = 'mobility-bot.stg-myteksi.com';
export const BRANCH_HOSTNAME = 'mobilitybot.webkit.stg-myteksi.com';
export const DEV_HOSTNAME = 'mobility-bot.grab.dev';

export const STG_API_ENDPOINT = 'https://p.stg-myteksi.com/api/passenger/transport/v1/gpt';
export const PRD_API_ENDPOINT = 'https://p.myteksi.net/api/passenger/transport/v1/gpt';

export const CONCEDO_CLIENT_ID = 'mobility-bot';

export const HEADER_HEIGHT = 70;
