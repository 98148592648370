import { jwtDecode } from 'jwt-decode';
import GrabStorage from 'grab-storage';
import { camelCase } from '@helpers/index';
import { DecodedUserInfo } from '@routes/login/common/types';

export const TOKEN_HEADER_KEY = 'X-XSRF-TOKEN';
export const KC_TOKEN_KEY = '__KC_TOKEN__';
export const TOKEN_EMPTY_ERROR = 'TOKEN_EMPTY_ERROR';
export const TOKEN_NAME = '__TOKEN__';
export const REFRESH_TOKEN = '__REFRESH_TOKEN__';
export const CLIENT_TOKEN = '__CLIENT_TOKEN__';
export const CONCEDO_STATE = '__CONCEDO_STATE__';
export const CONCEDO_ENABLE = '__CONCEDO_ENABLE__';
export const CONCEDO_REDIRECT_URL = '__CONCEDO_REDIRECT_URL__'; // This URL will be redirected to after login successfully
export const CONCEDO_REFRESH_TIME_ID = '__CONCEDO_REFRESH_TIME_ID__'; // This URL will be redirected to after login successfully
export const UNAUTH_ERROR_CODE = 'UNAUTHORIZED';
export const SWITCH_CONCEDO_ALERT_CODE = 'SWITCH_CONCEDO_ALERT_CODE';
export const SWITCH_GOOGLE_ALERT_CODE = 'SWITCH_GOOGLE_ALERT_CODE';
export const SWITCH_AUTH_ERROR = 'Authentication method is changed';

export function setConcedoState(state: string | number): void {
  GrabStorage.setItem(CONCEDO_STATE, state);
}

export function getConcedoState(): string | number | undefined {
  return GrabStorage.getItem(CONCEDO_STATE);
}

export function removeConcedoState(): void {
  GrabStorage.removeItem(CONCEDO_STATE);
}

export function getToken(): string | undefined {
  return GrabStorage.getItem(TOKEN_NAME);
}

export function getRefreshToken(): string | undefined {
  return GrabStorage.getItem(REFRESH_TOKEN);
}

export function setToken(token: string, refreshToken = ''): void {
  GrabStorage.setItem(TOKEN_NAME, token);
  if (refreshToken) GrabStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function removeToken(): void {
  return GrabStorage.removeItem(TOKEN_NAME);
}

export const camelizeToken = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeToken(v));
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeToken(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export function getDecodeToken(token?: string): DecodedUserInfo {
  const existToken = token || getToken();
  if (!existToken) return {};

  try {
    const decodedToken = jwtDecode(existToken);
    const userInfo: DecodedUserInfo = camelizeToken(decodedToken);
    return userInfo;
  } catch {
    return {};
  }
}

export const getUserName = () => {
  const decodedToken = getDecodeToken();

  if (Object.keys(decodedToken).length) {
    if (decodedToken.name) {
      return decodedToken.name.split('@')[0];
    }
    return 'anonymous';
  }

  return 'anonymous';
};

export const getUserEmail = () => {
  const decodedToken = getDecodeToken();

  if (Object.keys(decodedToken).length) {
    if (decodedToken.name) {
      return decodedToken.name;
    }
    return 'anonymous';
  }

  return 'anonymous';
};

export const encodeState = (key: string, redirectPath?: string): string => {
  const state = JSON.stringify({ key, redirectPath });
  return Buffer.from(state).toString('base64');
};

export const decodeState = (
  encodedStr: string,
): { key?: string; redirectPath?: string } => {
  try {
    const decodedStr = Buffer.from(encodedStr, 'base64').toString('ascii');
    const state = JSON.parse(decodedStr);
    return state;
  } catch {
    /* Failed to decode state, return empty object */
  }

  return {};
};
