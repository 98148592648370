import App from '@components/App';
import { datadogLogs } from '@datadog/browser-logs';
import Storage from 'grab-storage';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import '@duxton-web/inter';
import './globalStyles.less';
import {
  ConcedoAuthConfigProps,
  ConcedoAuthProvider,
  GetTokenProps,
  getRefreshToken,
} from '@data-platform/concedo-auth';
import { isProdEnv } from './helpers';
import api from '@helpers/api';
import { CONCEDO_CLIENT_ID } from './constants/appConfigs';

window.Grab = {
  config: {},
  storage: Storage,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000 /* Data is consider stale after 1 minute */,
      refetchOnWindowFocus: false,
    },
  },
});

/* Get the token and refreshToken value from your backend */
const handleGetToken = async ({ codeValue }: GetTokenProps) => {
  const res = await api.post(`/v1/auth/getToken`, {
    code: codeValue,
  });
  const { token, refreshToken } = res.data;
  return { token, refreshToken };
};

const handleRefreshToken = async () => {
  const res = await api.post(`/v1/auth/refreshToken`, {
    refreshToken: getRefreshToken(),
  });
  const { token, refreshToken } = res.data;
  return { token, refreshToken };
};

/* Set config */
const concedoAuthConfig: ConcedoAuthConfigProps = {
  clientId: CONCEDO_CLIENT_ID,
  handleGetToken,
  handleRefreshToken,
  isProdEnv: isProdEnv(),
  redirectUri: `${window.location.protocol}//${window.location.hostname}/login`,
};

function renderApp() {
  const container = document.getElementById('root');
  if (!container) return;

  const root = createRoot(container);
  root.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConcedoAuthProvider config={concedoAuthConfig}>
          <App />
        </ConcedoAuthProvider>
      </BrowserRouter>
    </QueryClientProvider>,
  );
}

window
  .fetch(require('config.json'))
  .then(async (configResponse) => {
    if (configResponse.status !== 200) return;
    window.Grab.config = await configResponse.json();

    const isProductionEnv = process.env.NODE_ENV === 'production';
    const datadogClientTokenExists =
      window.Grab.config.datadog && window.Grab.config.datadog.clientToken;

    if (isProductionEnv && datadogClientTokenExists) {
      datadogLogs.init({
        clientToken: window.Grab.config.datadog.clientToken,
        site: 'datadoghq.com',
        service: window.Grab.config.appId,
        env: window.Grab.config.env,
        version: process.env.APP_VERSION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
      window.onerror = (message, source, lineno, colno, error) => {
        if (error) {
          datadogLogs.logger.error(error.message || '', {
            error: { stack: error.stack },
          });
        }
      };
    }

    renderApp();
  })
  .catch((err) => {
    datadogLogs.logger.error(err.message, { error: { stack: err.stack } });
    console.error(err);
  });
