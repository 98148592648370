import { ProtectedRoute } from '@data-platform/concedo-auth';
import Spinner from '@components/Spinner';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import WithUpdateWorker from '@helpers/update-worker/WithUpdateWorker';

const LoginPage = lazy(() => import('@routes/login'));
const ChatPage = lazy(() => import('@routes/chat'));
const HomeLayout = lazy(() => import('src/layouts/HomeLayout'));

const App = () => (
  <React.Suspense fallback={<Spinner />}>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/*" element={<ProtectedRoute element={<HomeLayout />} />} />
    </Routes>
  </React.Suspense>
);

export default WithUpdateWorker(App);
