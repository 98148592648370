import React, { FC } from 'react';
import { Spin, SpinProps } from '@duxton-web/ui';
import styles from './index.less';

type ISpinnerProps = SpinProps;

const Spinner: FC<ISpinnerProps> = ({ size }) => (
  <div className={styles.wrapper}>
    <Spin size={size} />
  </div>
);

export default Spinner;
