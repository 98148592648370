/* istanbul ignore file */
import React, { FC, ComponentType, useEffect } from 'react';
import { notification } from '@duxton-web/ui';
import worker from './worker';

const onServiceWorkerUpdate = (eventName: string) => {
  switch (eventName) {
    case 'update-installed':
      notification.info({
        duration: 0,
        message: 'New Version Update',
        description:
          'A new version has been downloaded, please refresh the page to update to latest version.',
        placement: 'bottomLeft',
      });
      break;
    default:
  }
};

const WithUpdateWorker = (WrappedComponent: ComponentType): ComponentType => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithWorker: FC = (props) => {
    useEffect(() => {
      worker.register(onServiceWorkerUpdate);
    }, []);

    return <WrappedComponent {...props} />;
  };

  ComponentWithWorker.displayName = `WithUpdateWorker(${displayName})`;

  return ComponentWithWorker;
};

export default WithUpdateWorker;
